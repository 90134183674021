export type InterfaceServicesApiResponse<T> = {
  isSuccess: boolean;
  data: T | null;
  code: ISAResponseCodes | LocalErrCodes;
  message: string;
};

export enum LocalErrCodes {
  InvalidAuthParams = 601,
  UnAuthorized = 401,
}

export enum ISAResponseCodes {
  Success = 0,
  PageConfigNotFound = 1,
  MissingReqBody = 2, // common
  MissingReqBodyProperty = 3, // common
  InvalidReqBodyProperty = 4, // common
  NullInterfaceSettings = 5, // common
  AccountIsInactive = 6, // common
  InvalidFcResponseStruct = 7, // saving passenger with 2fa
  BadFcResponseCode = 8, // saving passenger with 2fa
  InvalidTwoFactorCode = 10, // saving passenger with 2fa
  TooManyTFAAttempts = 11, // saving passenger with 2fa
  FailedToSend2FASmsCode = 12, // sending sms
  NullSettingsObject = 13, // common
  AccountNotExistsOrNotActive = 14, // common
  Unknown = 99, // common
}

export type PageConfig = {
  yitPrivacyAgreementUrl: string;
  accountPageConfig: {
    sitePrivacyAgreementUrl: string;
    accountCode: string;
    accountName: string;
    isActive: 0 | 1;
    defaultLanguage: string;
    showEmailInput: boolean;
    showUniqueIdInput: boolean;
    uniqueIdInputLabels: {
      he: string;
      en: string;
      ru: string;
      ar: string;
    };
  };
};
