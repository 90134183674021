import { Tooltip } from '@mui/material';
import React, { ComponentProps, FC, PropsWithChildren } from 'react';
import { useA11yTheme } from 'src/styles/useA11yTheme';

type Props = {} & ComponentProps<typeof Tooltip>;

const BaseTooltip: FC<Props> = ({ children, ...tooltipProps }) => {
  const { theme } = useA11yTheme();

  const sx = React.useMemo(() => {
    return {
      '.MuiTooltip-tooltipArrow': {
        backgroundColor: theme.colors.white,
        padding: '14px',
        fontSize: 14,
        boxShadow: '0px 5px 6px rgba(217, 226, 233, 0.5)',
        borderRadius: '9px',
        color: theme.colors.black,
        border: '1px solid rgba(180, 190, 201, 0.303017)',
        fontWeight: 400,
        textAlign: 'right',
        fontFamily: 'Rubik',
      },
      '.MuiTooltip-arrow': {
        color: 'white',
        '&:before': {
          border: '1px solid rgba(180, 190, 201, 0.303017)',
        },
      },
    };
  }, [theme]);

  return (
    <Tooltip
      PopperProps={{
        sx,
      }}
      arrow
      {...tooltipProps}
    >
      {children as React.ReactElement}
    </Tooltip>
  );
};

export default BaseTooltip;
