import { useEffect } from 'react';

// Define the type for the callback function
type CallbackFunction = () => void;

const useEnterKey = (callback: CallbackFunction): void => {
  useEffect(() => {
    // Function to handle key press
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        callback();
      }
    };

    // Adding event listener
    window.addEventListener('keydown', handleKeyPress);

    // Cleanup function
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [callback]); // Dependency array to ensure the effect runs when callback changes
};

export default useEnterKey;
