import { LoadingButton } from '@mui/lab';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import OTPInput from 'react-otp-input';
import { api } from 'src/api/api';
import { ISAResponseCodes } from 'src/api/types';
import { BaseButton } from 'src/components/BaseButton';
import { BaseCard, CardSubtitle, CardTitle } from 'src/components/BaseCard';
import { useDispatchAlert } from 'src/hooks/useAlert';
import useEnterKey from 'src/hooks/useEnterKey';
import { useErrorsTextsByCode } from 'src/hooks/useErrorsTextsByCode';
import { useIsRTL } from 'src/hooks/useIsRTL';
import { useSendTFASms } from 'src/hooks/useSendTFASms';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { authRootSelector } from 'src/store/slices/authSlice';
import { isMobileSelector } from 'src/store/slices/commonSlice';
import { formSelector, mainSliceActions } from 'src/store/slices/mainSlice';
import { flexCenter, flexCol } from 'src/styles/commonStyles';
import { useA11yTheme } from 'src/styles/useA11yTheme';
import styled, { css } from 'styled-components';

export const TwoFa: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const dispatchAlert = useDispatchAlert();
  const errorsTextByCode = useErrorsTextsByCode();
  const tfaSms = useSendTFASms();
  const { theme } = useA11yTheme();
  const isRTL = useIsRTL();

  const [code, setCode] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [tooManyAttemptsCooldown, setTooManyAttemptsCooldown] = React.useState(false);
  const [isResendSmsDebounced, setIsResendSmsDebounced] = React.useState(false);

  const authParams = useAppSelector((state) => authRootSelector(state).params);
  const values = useAppSelector((state) => formSelector(state).values);
  const isMobile = useAppSelector((state) => isMobileSelector(state));

  const lastSmsSent = React.useRef<null | Date>(null);
  const lastSubmittedCodeRef = React.useRef('');

  React.useEffect(() => {
    if (!isResendSmsDebounced) return;
    const timeout = setTimeout(() => {
      setIsResendSmsDebounced(false);
    }, 1000 * 60);

    return () => clearTimeout(timeout);
  }, [isResendSmsDebounced]);

  const onResendSms = () => {
    // debounce
    const currTime = new Date();
    if (lastSmsSent.current && currTime.getTime() - lastSmsSent.current.getTime() < 1000 * 60) {
      setIsResendSmsDebounced(true);
      return;
    }

    tfaSms.send();

    setIsResendSmsDebounced(false);

    lastSmsSent.current = currTime;
  };

  const onClickSubmit = useCallback(async () => {
    try {
      if (lastSubmittedCodeRef.current === code) {
        dispatchAlert('error', t('invalidTwoFactorCode'));
        return;
      }

      lastSubmittedCodeRef.current = code;
      setIsLoading(true);

      const response = await api.savePassengerWithTwoFa({
        ...authParams,
        twoFactorCode: Number(code),
        passengerData: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          mobilePhone: values.phoneNum,
          uniqueId: values.uniqueId,
        },
      });

      if (!response.isSuccess) {
        dispatchAlert('error', errorsTextByCode.getErrMsg('savePassengerWithTwoFaErrors', response.code));

        if (response.code === ISAResponseCodes.TooManyTFAAttempts) {
          setTooManyAttemptsCooldown(true);
        }

        return;
      }

      // handling success
      dispatch(mainSliceActions.setStage('success'));
    } catch (error) {
      dispatchAlert('error', t('errorSavingPassenger'));
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [authParams, code, values]);

  useEnterKey(onClickSubmit);

  const getHiddenPhoneNum = () => {
    const ending = values.phoneNum.slice(values.phoneNum.length - 4);

    if (isRTL) return `${ending}******`;
    return `******${ending}`;
  };

  React.useEffect(() => {
    if (!tooManyAttemptsCooldown) return;

    const timeout = setTimeout(() => {
      setTooManyAttemptsCooldown(false);
    }, 1000 * 60);

    return () => clearTimeout(timeout);
  }, [tooManyAttemptsCooldown]);

  return (
    <BaseCard style={{}}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <CardTitle>{t('phoneConfirmation')}</CardTitle>
          <CardSubtitle withMarginBottom={false}>
            {t('smsSent')}
            {getHiddenPhoneNum()}
          </CardSubtitle>
          <CardSubtitle>{t('enterBelow')}</CardSubtitle>
        </div>
        <div style={{ ...flexCol, alignItems: 'center' }}>
          <OTPInput
            value={code}
            inputType="tel"
            i18nIsDynamicList={false}
            onChange={(val) => {
              setCode(val);
            }}
            numInputs={4}
            shouldAutoFocus
            containerStyle={{
              display: 'flex',
              flexDirection: isRTL ? 'row-reverse' : 'row',
              gap: '16px',
              justifyContent: 'space-between',
              marginBottom: '20px',
            }}
            inputStyle={{
              width: '40px',
              height: '56px',
              fontSize: '18px',
              borderRadius: '8px',
              border: `1px solid ${theme.colors.midGray}`,
            }}
            renderInput={(props) => <input {...props} />}
          />

          <div
            style={{
              fontWeight: 400,
              fontSize: '16px',
              color: theme.textColors.primaryTextColor,
              marginBottom: '20px',
            }}
          >
            {t('didNotReceiveCode')}{' '}
            <SendAgainSpan
              onClick={onResendSms}
              isDisabled={tooManyAttemptsCooldown || tfaSms.isLoading || isResendSmsDebounced}
            >
              {t('sendAgain')}
            </SendAgainSpan>
          </div>
        </div>
        <BaseButton
          isLoading={isLoading}
          onClick={onClickSubmit}
          text={t('continue')}
          ButtonProps={{
            disabled: code.length !== 4 || tooManyAttemptsCooldown,
          }}
        />
      </div>
    </BaseCard>
  );
};

const SendAgainSpan = styled.span<{ isDisabled: boolean }>`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  text-decoration: underline;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.3;
      cursor: default;
      pointer-events: none;
    `};
`;
