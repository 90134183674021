import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { BaseCard, CardSubtitle, CardTitle } from 'src/components/BaseCard';
import GetOnPlayStore from 'src/components/Icons/GetOnPlayStore';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { isMobileSelector } from 'src/store/slices/commonSlice';
import { a11ySelector, mainSliceActions } from 'src/store/slices/mainSlice';
import { flexCol } from 'src/styles/commonStyles';
import { useA11yTheme } from 'src/styles/useA11yTheme';
import GetOnAppStore from '../../../../components/Icons/GetOnAppStore';

const APP_ANDROID_URL = 'https://play.google.com/store/apps/details?id=com.mywayyit&hl=en&gl=US';
const APP_IOS_URL = 'https://apps.apple.com/uz/app/my-way/id1526974390';

export const DownloadApp: React.FC<{}> = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const a11y = useAppSelector((state) => a11ySelector(state));
  const isMobile = useAppSelector((state) => isMobileSelector(state));
  const { theme } = useA11yTheme();

  return (
    <BaseCard>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', height: '100%', maxWidth: '320px' }}>
        <CardTitle>{t('downloadApp')}</CardTitle>
        {isMobile ? (
          <CardSubtitle>{t('downloadAppExplanation')}</CardSubtitle>
        ) : (
          <>
            <CardSubtitle withMarginBottom={false}>{t('downloadAppExplanationWeb1')}</CardSubtitle>
          </>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              gap: '16px',
              justifyContent: isMobile ? 'center' : 'space-between',
              alignItems: 'center',
              width: '100%',
              paddingBottom: '16px',
              height: '100%',
              marginTop: '20px'
            }}
          >
            <div style={{ ...flexCol, gap: '20px' }}>
              {!isMobile && (
                <QRCode
                  size={160}
                  style={{ height: 'auto', maxWidth: '100%', width: '100%'}}
                  value={APP_ANDROID_URL}
                  viewBox={`0 0 256 256`}
                />
              )}
              <a href={APP_ANDROID_URL} target="_blank" rel="noopener noreferrer">
                <div style={{ maxHeight: '49px', borderRadius: '7px', background: 'white' }}>
                  <GetOnPlayStore isBlackAndWhite={a11y.contrast === 'blackAndWhite'} />
                </div>
              </a>
            </div>
            <div style={{ ...flexCol, gap: '20px' }}>
              {!isMobile && (
                <QRCode
                  size={160}
                  style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                  value={APP_IOS_URL}
                  viewBox={`0 0 256 256`}
                />
              )}
              <a href={APP_IOS_URL} target="_blank" rel="noopener noreferrer">
                <div style={{ maxHeight: '49px', borderRadius: '7px', background: 'white' }}>
                  <GetOnAppStore />
                </div>
              </a>
            </div>
          </div>
          <Button
            onClick={() => {
              dispatch(mainSliceActions.resetForm());
              dispatch(mainSliceActions.setStage('form'));
            }}
            sx={{
              '&.MuiButton-text': {
                fontSize: '18px',
                color: theme.colors.primary,
                textDecoration: 'underline',
              },
            }}
          >
            {t('addAnotherPassenger')}
          </Button>
        </div>
      </div>
    </BaseCard>
  );
};
