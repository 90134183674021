import { useAppDispatch } from 'src/store/hooks';
import { LangKeys, mainSliceActions } from 'src/store/slices/mainSlice';

export const useLanguageUtils = () => {
  const dispatch = useAppDispatch();

  const setLanguage = (language: LangKeys) => {
    dispatch(mainSliceActions.setLanguage(language));
    dispatch(mainSliceActions.resetFormErrors()); // to avoid having to retranslate all the errors shown
  };

  return { setLanguage };
};
