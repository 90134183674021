import { t } from 'i18next';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsRTL } from 'src/hooks/useIsRTL';
import { useAppSelector } from 'src/store/hooks';
import { isMobileSelector } from 'src/store/slices/commonSlice';
import { heights } from 'src/styles/commonStyles';
import { useA11yTheme } from 'src/styles/useA11yTheme';
import styled from 'styled-components';
import yitLogo from '../images/yitLogo.png';
import { useOnMountEffect } from 'src/hooks/useOnMountEffect';
import axios from 'axios';
import { Endpoints, createUrl } from 'src/api/utilis';

interface Props {}

const InfoFooter: FC<Props> = ({}) => {
  const { t } = useTranslation();

  const isRTL = useIsRTL();
  const [apiVer, setApiVer] = React.useState('');

  const isMobile = useAppSelector((state) => isMobileSelector(state));
  const { theme } = useA11yTheme();

  useOnMountEffect(() => {
    const asyncHandler = async () => {
      try {
        const url = createUrl(Endpoints.GetServerVersion);

        const res = await axios.post(url, {});

        if (res && res.data && res.data.data) {
          setApiVer(res.data.data);
          return;
        }

      } catch (error) {
        console.log(error);
      }
    };

    asyncHandler();
  });

  return (
    <InfoFooterDiv>
      <div style={{ display: 'flex', alignItems: 'center', width: '200px' }}>
        <YitLogo />
        <div style={{ display: 'flex', gap: '20px', fontSize: '12px' }}>
          <a
            href="https://www.y-it.co.il/%d7%a6%d7%95%d7%a8-%d7%a7%d7%a9%d7%a8"
            target="_blank"
            rel="noreferrer"
            style={{ color: theme.textColors.primaryTextColor }}
          >
            {t('supportAndContact')}
          </a>
        </div>
      </div>

      {!isMobile && (
        <div
          style={{
            color: theme.textColors.primaryTextColor,
            fontSize: '12px',
            fontFamily: 'Rubik',
            fontWeight: '400',
          }}
        >
          Powered by Y-IT
        </div>
      )}

      <div style={{ padding: '0 10px', width: '200px' }}>
        <p
          style={{
            textAlign: isRTL ? 'left' : 'right',
            color: theme.textColors.primaryTextColor,
            fontSize: '12px',
          }}
        >
          Ver {process.env.REACT_APP_VER} {apiVer}
        </p>
      </div>
    </InfoFooterDiv>
  );
};

const InfoFooterDiv = styled.div<{}>`
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  height: ${heights.infoFooter}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
`;

export const YitLogo: React.FC<{
  style?: React.CSSProperties;
}> = ({ style = {} }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${yitLogo})`,
        width: '50px',
        height: '20px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        ...style,
      }}
    />
  );
};

export default InfoFooter;
