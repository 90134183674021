import React from 'react';

function A11yNormalContrast() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11.25" stroke="#494949" stroke-width="1.5" />
    </svg>
  );
}

export default A11yNormalContrast;
