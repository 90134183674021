import React from 'react';
import { useAppSelector } from 'src/store/hooks';
import { InputConfig } from './types.form';
import { Endpoints } from 'src/api/utilis';
import { useEffectTrigger } from 'src/hooks/useEffectTrigger';

export const useScrollToInputWithErrors = ({
  textInputs,
  errors,
  yitPrivacyPolicyInputRefIndex,
  sitePrivacyPolicyInputRefIndex,
  inputsRefs,
}: {
  textInputs: InputConfig[];
  errors: {
    firstName?: string[] | undefined;
    lastName?: string[] | undefined;
    phoneNum?: string[] | undefined;
    uniqueId?: string[] | undefined;
    email?: string[] | undefined;
    acceptedYitPrivacyAgreement?: string[] | undefined;
    acceptedSitePrivacyAgreement?: string[] | undefined;
  };
  yitPrivacyPolicyInputRefIndex: number;
  sitePrivacyPolicyInputRefIndex: number;
  inputsRefs: React.MutableRefObject<any[]>;
}) => {
  const accountPageConfig = useAppSelector(
    (state) => state.main.api[Endpoints.GetPageConfig].data?.accountPageConfig
  );

  const scrollToFieldsWithErrors = () => {
    // Find the first element with an error
    let firstErrIndex = textInputs.findIndex((ti) => errors[ti.fieldKey]?.length);

    if (firstErrIndex === -1 && errors.acceptedYitPrivacyAgreement?.length) {
      firstErrIndex = yitPrivacyPolicyInputRefIndex;
    }

    if (firstErrIndex === -1 && errors.acceptedSitePrivacyAgreement?.length) {
      firstErrIndex = sitePrivacyPolicyInputRefIndex;
    }

    console.log('scrolling to index', firstErrIndex);
    if (firstErrIndex !== -1 && inputsRefs.current[firstErrIndex]) {
      inputsRefs.current[firstErrIndex].current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };

  React.useEffect(() => {
    let count = textInputs.length + 1; // + 1 because yit privacy policy
    if (accountPageConfig?.showEmailInput) {
      count = count + 1;
    }
    if (accountPageConfig?.showUniqueIdInput) {
      count = count + 1;
    }
    if (accountPageConfig?.sitePrivacyAgreementUrl) {
      count = count + 1;
    }

    for (let i = 0; i < count; i++) {
      inputsRefs.current.push(React.createRef());
    }
  }, [textInputs.length]);

  const { trigger: triggerScrollToFieldsWithErrors } = useEffectTrigger(scrollToFieldsWithErrors);

  return {
    triggerScrollToFieldsWithErrors,
  };
};
