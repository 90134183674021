import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ISAResponseCodes } from '../api/types';

export const getErrMsgWithCode = (msg: string, code: number, t: TFunction) => {
  return `${t(msg)} ( ${t('code')} ${code} )`;
};

export const useErrorsTextsByCode = () => {
  const { t } = useTranslation();

  const getErrCodeText = (code: number) => {
    return `( ${t('code')} ${code} )`;
  };

  const commonErrors = {
    [ISAResponseCodes.Unknown]: `${t('unknownErr')} ${getErrCodeText(ISAResponseCodes.Unknown)}`,
    [ISAResponseCodes.MissingReqBody]: `${t('unknownErr')} ${getErrCodeText(
      ISAResponseCodes.MissingReqBody
    )}`,
    [ISAResponseCodes.MissingReqBodyProperty]: `${t('unknownErr')} ${getErrCodeText(
      ISAResponseCodes.MissingReqBodyProperty
    )}`,
    [ISAResponseCodes.InvalidReqBodyProperty]: `${t('unknownErr')} ${getErrCodeText(
      ISAResponseCodes.InvalidReqBodyProperty
    )}`,
  } as { [key in ISAResponseCodes]: string };

  const getPageConfigErrors = {
    ...commonErrors,
    [ISAResponseCodes.NullInterfaceSettings]: `${t('invalidWebsiteUrl')} ${getErrCodeText(
      ISAResponseCodes.NullInterfaceSettings
    )}`,
    [ISAResponseCodes.NullSettingsObject]: `${t('invalidWebsiteUrl')} ${getErrCodeText(
      ISAResponseCodes.NullSettingsObject
    )}`,
    [ISAResponseCodes.AccountNotExistsOrNotActive]: `${t('accountNotExistsOrNotActive')} ${getErrCodeText(
      ISAResponseCodes.AccountNotExistsOrNotActive
    )}`,
  } as { [key in ISAResponseCodes]: string };

  const sendSmsErrors = {
    ...commonErrors,
    [ISAResponseCodes.NullInterfaceSettings]: `${t('errorSendingSms')} ${getErrCodeText(
      ISAResponseCodes.NullInterfaceSettings
    )}`,
    [ISAResponseCodes.NullSettingsObject]: `${t('errorSendingSms')} ${getErrCodeText(
      ISAResponseCodes.NullSettingsObject
    )}`,
    [ISAResponseCodes.AccountNotExistsOrNotActive]: `${t('errorSendingSms')} ${getErrCodeText(
      ISAResponseCodes.AccountNotExistsOrNotActive
    )}`,
    [ISAResponseCodes.FailedToSend2FASmsCode]: `${t('errorSendingSms')} ${getErrCodeText(
      ISAResponseCodes.FailedToSend2FASmsCode
    )}`,
  } as { [key in ISAResponseCodes]: string };

  const savePassengerWithTwoFaErrors = {
    ...commonErrors,
    [ISAResponseCodes.NullInterfaceSettings]: `${t('errSavingPassengerData')} ${getErrCodeText(
      ISAResponseCodes.NullInterfaceSettings
    )}`,
    [ISAResponseCodes.NullSettingsObject]: `${t('errSavingPassengerData')} ${getErrCodeText(
      ISAResponseCodes.NullSettingsObject
    )}`,
    [ISAResponseCodes.AccountNotExistsOrNotActive]: `${t('errSavingPassengerData')} ${getErrCodeText(
      ISAResponseCodes.AccountNotExistsOrNotActive
    )}`,
    [ISAResponseCodes.InvalidFcResponseStruct]: `${t('errSavingPassengerData')} ${getErrCodeText(
      ISAResponseCodes.InvalidFcResponseStruct
    )}`,
    [ISAResponseCodes.BadFcResponseCode]: `${t('errSavingPassengerData')} ${getErrCodeText(
      ISAResponseCodes.BadFcResponseCode
    )}`,
    [ISAResponseCodes.InvalidTwoFactorCode]: `${t('invalidTwoFactorCode')}`,
    [ISAResponseCodes.TooManyTFAAttempts]: `${t('tooManyTFAAttempts')}`,
  } as { [key in ISAResponseCodes]: string };

  const msgByCodeObjects = {
    commonErrors,
    getPageConfigErrors,
    sendSmsErrors,
    savePassengerWithTwoFaErrors,
  };

  const getErrMsg = (errorsObjKey: keyof typeof msgByCodeObjects, code: number): string => {
    try {
      const msg = (msgByCodeObjects as Record<string, any>)[errorsObjKey][code];
      if (!msg) {
        throw new Error('error message by code not found');
      }
      return msg;
    } catch (error) {
      console.log(error);
      return commonErrors[ISAResponseCodes.Unknown]; // unknown error
    }
  };

  return { getErrMsg };
};
