import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { MOBILE_WIDTH_BREAKPOINT } from 'src/hooks/dimensions';

type HTMLInputProps = React.HTMLProps<HTMLInputElement>;

const BaseTextField = ({
  label,
  inputProps,
  containerStyle = {},
  errors = [],
  containerRef,
}: {
  label?: string;
  inputProps: HTMLInputProps & {
    error?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  };
  containerStyle?: React.CSSProperties;
  errors?: string[];
  containerRef?: React.RefObject<HTMLDivElement>;
}) => {
  const { t } = useTranslation();

  const contStyle = {
    gap: '3px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    ...containerStyle,
  } as const;

  return (
    <div style={contStyle} ref={containerRef}>
      <LabelStyled disabled={inputProps.disabled}>{label}</LabelStyled>
      <StyledTextInput type="text" {...(inputProps as any)} />
      {errors.map((e) => {
        return <FieldErrorMsg key={e}>{e}</FieldErrorMsg>;
      })}
    </div>
  );
};

export default BaseTextField;

export const StyledTextInput = styled.input<{
  error?: boolean;
  disabled?: boolean;
}>`
  margin: 0;
  padding: 1px;
  width: 100%;
  height: 40px;
  background-color: ${(props) => props.theme.colors.white};
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid rgb(120 120 120 / 48%);
  font-size: 16px;
  text-indent: 10px;
  color: ${(props) => props.theme.textColors.primaryTextColor};
  :focus {
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.blue};
    padding: 0;
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      border: 1px solid rgb(120 120 120);
    `}
  ${({ error }) =>
    error
      ? css`
          border: 1px solid ${(props) => props.theme.colors.muiRed} !important;
          padding: 1px;
          :focus {
            padding: 1px !important;
          }
        `
      : ``}
`;

const isMobile = screen.width < MOBILE_WIDTH_BREAKPOINT;
export const LabelStyled = styled.label<{ disabled?: boolean }>`
  font-size: ${isMobile ? '15px' : '14px'};
  color: ${(props) => props.theme.textColors.primaryTextColor};
  ${({ disabled }) => (disabled ? `opacity: 0.5` : '')}
`;

export const FieldErrorMsg = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.colors.muiRed};
`;
