import React from 'react';
import { FieldErrorMsg } from 'src/components/BaseTextField';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useAppSelector } from 'src/store/hooks';
import { isMobileSelector } from 'src/store/slices/commonSlice';

export const CheckboxWithLabel: React.FC<{
  id: string;
  label: string | (() => JSX.Element);
  error?: string;
  checked: boolean;
  onClick: () => void;
  isDisabled?: boolean;
  containerRef?: React.RefObject<HTMLDivElement>;
}> = ({ id, label, error = '', checked, onClick, isDisabled = false, containerRef }) => {
  const { t } = useTranslation();
  const isMobile = useAppSelector((state) => isMobileSelector(state));

  return (
    <Cont isDisabled={isDisabled} ref={containerRef}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
        <StyledCheckbox type="checkbox" id={id} checked={checked} onClick={onClick} readOnly />
        <Label htmlFor={id} style={{ cursor: 'pointer', fontSize: isMobile ? '15px' : '14px' }}>
          {typeof label === 'string' ? label : label()}
        </Label>
      </div>
      {error && <FieldErrorMsg style={{}}>{t(error)}</FieldErrorMsg>}
    </Cont>
  );
};

const Cont = styled.div<{ isDisabled: boolean }>`
  margin-top: 6px;
  ${(props) => (props.isDisabled ? `opacity: 0.5; pointer-events: none` : '')}
`;

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  accent-color: gray;
  color: ${(props) => props.theme.textColors.primaryTextColor};
  &:checked {
    accent-color: ${(props) => props.theme.colors.primary}; /* Change this color to your preference */
  }
`;

const Label = styled.label<{}>`
  color: ${(props) => props.theme.textColors.primaryTextColor};
  ${(props) => (props.theme.isLinkHighlightingActive ? `text-decoration: underline;` : '')}
`;
