/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

function A11yLinkHighlight({}) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2708_35536" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2708_35536)">
        <path
          d="M7.03845 16.5383C5.78282 16.5383 4.7125 16.0958 3.8275 15.211C2.9425 14.3261 2.5 13.2559 2.5 12.0004C2.5 10.7449 2.9425 9.67455 3.8275 8.7893C4.7125 7.90405 5.78282 7.46143 7.03845 7.46143H10.0577C10.2702 7.46143 10.4483 7.53333 10.592 7.67715C10.7358 7.82095 10.8077 7.99915 10.8077 8.21175C10.8077 8.42433 10.7358 8.60242 10.592 8.746C10.4483 8.88958 10.2702 8.96138 10.0577 8.96138H7.0373C6.19832 8.96138 5.48235 9.25785 4.8894 9.8508C4.29645 10.4438 3.99998 11.1601 3.99998 11.9999C3.99998 12.8396 4.29645 13.556 4.8894 14.1489C5.48235 14.7419 6.19832 15.0383 7.0373 15.0383H10.0577C10.2702 15.0383 10.4483 15.1102 10.592 15.254C10.7358 15.3978 10.8077 15.576 10.8077 15.7886C10.8077 16.0012 10.7358 16.1793 10.592 16.3229C10.4483 16.4665 10.2702 16.5383 10.0577 16.5383H7.03845ZM8.99998 12.7498C8.78748 12.7498 8.60936 12.6779 8.46563 12.5341C8.32188 12.3903 8.25 12.2121 8.25 11.9995C8.25 11.7869 8.32188 11.6088 8.46563 11.4653C8.60936 11.3217 8.78748 11.2499 8.99998 11.2499H15C15.2125 11.2499 15.3906 11.3218 15.5344 11.4656C15.6781 11.6094 15.75 11.7876 15.75 12.0002C15.75 12.2128 15.6781 12.3909 15.5344 12.5345C15.3906 12.678 15.2125 12.7498 15 12.7498H8.99998ZM13.9423 16.5383C13.7298 16.5383 13.5517 16.4664 13.4079 16.3226C13.2642 16.1788 13.1923 16.0006 13.1923 15.788C13.1923 15.5754 13.2642 15.3973 13.4079 15.2537C13.5517 15.1101 13.7298 15.0383 13.9423 15.0383H16.9627C17.8016 15.0383 18.5176 14.7419 19.1106 14.1489C19.7035 13.556 20 12.8396 20 11.9999C20 11.1601 19.7035 10.4438 19.1106 9.8508C18.5176 9.25785 17.8016 8.96138 16.9627 8.96138H13.9423C13.7298 8.96138 13.5517 8.88948 13.4079 8.74568C13.2642 8.60186 13.1923 8.42366 13.1923 8.21107C13.1923 7.99847 13.2642 7.82038 13.4079 7.6768C13.5517 7.53322 13.7298 7.46143 13.9423 7.46143H16.9615C18.2171 7.46143 19.2875 7.90387 20.1725 8.78875C21.0575 9.67363 21.5 10.7438 21.5 11.9993C21.5 13.2548 21.0575 14.3252 20.1725 15.2104C19.2875 16.0957 18.2171 16.5383 16.9615 16.5383H13.9423Z"
          fill="#1C1B1F"
        />
      </g>
    </svg>
  );
}

export default A11yLinkHighlight;
