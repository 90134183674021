import React from 'react';

export const useOnMountEffect = (cb: Function) => {
  const fired = React.useRef(false);

  React.useEffect(() => {
    if (fired.current) return;

    fired.current = true;
    cb();
  }, []);
};
