import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LangKeys } from './store/slices/mainSlice';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  he: {
    translation: {
      firstName: 'שם פרטי',
      lastName: 'שם משפחה',
      phoneNum: 'מספר טלפון',
      email: 'כתובת מייל',
      acceptTermsAndCon: 'אני מאשר את',
      of: 'של',
      ok: 'אישור',
      formTitle: 'טופס הרשמה',
      formSubtitle1: 'אנחנו שמחים שבחרתם להשתמש בשירות ההסעות שלנו',
      formSubtitle2: 'על מנת להרשם,',
      formSubtitle3: 'אנא מלאו את הפרטים הבאים:',
      login: 'התחברות',
      continue: 'המשך',
      phoneNumber: 'מספר טלפון',
      phoneConfirmation: 'אימות טלפון',
      smsSent: 'קוד אימות חד פעמי נשלח למספר ',
      enterBelow: 'אנא הכנס אותו למטה',
      signupSuccess: 'רישום בוצע בהצלחה',
      downloadApp: 'הורדת האפליקציה',
      downloadAppExplanation: 'להורדת אפליקציית MyWay מהחנות',
      downloadAppExplanationWeb1: 'להורדת אפליקציית MyWay סירקו QR',
      addAnotherPassenger: 'רישום משתמש נוסף',
      sendAgain: 'שלח שוב',
      didNotReceiveCode: 'לא קיבלת קוד?',
      supportAndContact: 'יצירת קשר ותמיכה',
      requiredField: 'שדה חובה',
      invalidValue: 'ערך לא תקין',
      tooShort: 'ערך קצר מידי',
      atLeast: 'לפחות',
      digits: 'ספרות',
      min: 'מינימום',
      mustAcceptPrivacyStatement: 'יש לאשר את מדיניות הפרטיות',
      privacyStatement: 'מדיניות הפרטיות',
      unknownErr: 'תקלה זמנית, ניתן ליצור קשר עם התמיכה בטלפון 08-9464288',
      code: 'קוד',
      invalidAuthParams: 'קישור הכניסה למערכת אינו תקין',
      invalidTwoFactorCode: 'קוד אינו תקין',
      errorSendingSms: 'שגיאה - לא ניתן לשלוח SMS',
      errorSavingPassenger: 'שגיאה בשמירת פרטי הנוסע',
      uniqueId: 'מזהה נוסע',
      invalidWebsiteUrl: 'קישור הכניסה למערכת אינו תקין',
      tooManyTFAAttempts: 'יותר מידי נסיונות אימות, ניתן לנסות שוב עוד 60 שניות',
      errSavingPassengerData: 'שגיאה בשמירת נתוני הנוסע',
      cancelAcc: 'ביטול נגישות',
      linkHighlighting: 'הדגש קישורים',
      moreSettings: 'הגדרות נוספות',
      contrast: 'ניגודיות',
      normalContrast: 'ניגודיות רגילה',
      highContrast: 'ניגודיות גבוהה',
      blackAndWhite: 'שחור - לבן',
      accMenu: 'תפריט נגישות',
      a11y: 'נגישות',
      noYitPrivacyAgreement: 'לא נמצאה מדיניות הפרטיות',
      accountNotExistsOrNotActive: 'אתר אינו פעיל או אינו קיים',
      // new
      pageNotFound: 'זמנית לא ניתן לבצע רישום דרך האתר, אנא פנה לספק השירות.',
      refresh: 'רענון',
    },
  },
  en: {
    translation: {
      firstName: 'First name',
      lastName: 'Last name',
      phoneNum: 'Phone number',
      email: 'Email address',
      acceptTermsAndCon: ' I confirm',
      of: 'of',
      ok: 'OK',
      formTitle: 'Registration form',
      formSubtitle1: 'We are glad that you have chosen to use our shuttle service.',
      formSubtitle2: 'In order to sign up,',
      formSubtitle3: 'Please fill in the following information:',
      login: 'Log in',
      continue: 'Continue',
      phoneNumber: 'Phone number',
      phoneConfirmation: 'Phone Verification',
      smsSent: 'A one-time verification code has been sent to ',
      enterBelow: 'Please insert it below',
      signupSuccess: 'Registration was done successfully',
      downloadApp: 'Download the app',
      downloadAppExplanation: 'To download MyWay app from the store',
      downloadAppExplanationWeb1: 'To download MyWay app, scan QR',
      addAnotherPassenger: 'Register another user',
      sendAgain: 'Resend',
      didNotReceiveCode: "Didn't get a code?",
      supportAndContact: 'Contact and support',
      requiredField: 'Required field',
      invalidValue: 'Invalid value',
      tooShort: 'Too short value',
      atLeast: 'At least',
      digits: 'Digits',
      min: 'Minimum',
      mustAcceptPrivacyStatement: 'Accept Privacy Policy',
      privacyStatement: 'Privacy Policy',
      unknownErr: 'Temporary malfunction, please contact support at 08-9464288',
      code: 'Code',
      invalidAuthParams: 'Login link is invalid',
      invalidTwoFactorCode: 'Invalid code',
      errorSendingSms: 'Error - Unable to send sms',
      errorSavingPassenger: 'Error saving passenger details',
      uniqueId: 'Passenger ID',
      invalidWebsiteUrl: 'Login link is invalid',
      tooManyTFAAttempts: 'Too many verification attempts, please try again in 60 seconds',
      errSavingPassengerData: 'Error saving passenger’s data',
      cancelAcc: 'Cancel accessibility',
      linkHighlighting: 'Highlight links',
      moreSettings: 'More settings',
      contrast: 'Contrast',
      normalContrast: 'Normal Contrast',
      highContrast: 'High contrast',
      blackAndWhite: 'Black & White',
      accMenu: 'Accessibility menu',
      a11y: 'Accessibility',
      noYitPrivacyAgreement: 'Privacy policy not found',
      accountNotExistsOrNotActive: 'Site is inactive or does not exist',
      pageNotFound:
        'Registration through the website is temporarily unavailable, please contact the service provider.',
      refresh: 'Refresh',
    },
  },
  ar: {
    translation: {
      firstName: 'الاسم الشخصي',
      lastName: 'اسم العائلة',
      phoneNum: 'رقم الهاتف',
      email: 'عنوان البريد الالكتروني',
      acceptTermsAndCon: 'أنا أؤكد',
      of: 'ل',
      ok: 'موافقة',
      formTitle: 'إستمارة تسجيل',
      formSubtitle1: 'نحن سعداء أنكم اخترتم استخدام خدمة النقل الخاصة بنا',
      formSubtitle2: 'للتسجيل،',
      formSubtitle3: 'يرجى ملء التفاصيل التالية:',
      login: 'الاتصال',
      continue: 'اكمل',
      phoneNumber: 'رقم الهاتف',
      phoneConfirmation: 'التحقق من الهاتف',
      smsSent: 'يتم إرسال رمز التحقق لمرة واحدة إلى الرقم ',
      enterBelow: 'الرجاء إدخاله أدناه',
      signupSuccess: 'تم التسجيل بنجاح',
      downloadApp: 'تنزيل التطبيق',
      downloadAppExplanation: 'لتنزيل تطبيق MyWay من المتجر',
      downloadAppExplanationWeb1: 'لتحميل التطبيق، قوموا بمسح لتحميل لتحميل تطبيق MyWay، امسحوا الرمز الشريطي QR',
      downloadAppExplanationWeb2: 'أو احصلوا على رابط التحميل للهاتف النقال',
      addAnotherPassenger: 'تسجيل مستخدم آخر',
      sendAgain: 'إعادة الإرسال',
      didNotReceiveCode: 'لم تحصل على رمز؟',
      supportAndContact: 'اجراء الاتصال والدعم',
      requiredField: 'حقل الزامي',
      invalidValue: 'قيمة غير صالحة',
      tooShort: 'قيمة قصيرة جدًا',
      atLeast: 'على الاقل',
      digits: 'أرقام',
      min: 'حد أدنى',
      mustAcceptPrivacyStatement: 'يجب الموافقة على سياسة الخصوصية',
      privacyStatement: 'سياسة الخصوصية',
      unknownErr: 'عطل مؤقت، يمكنك الاتصال بالدعم على هاتف رقم 08-9464288',
      code: 'الرمز',
      invalidAuthParams: 'رابط تسجيل الدخول للمنظومة غير صالح',
      invalidTwoFactorCode: 'الرمز غير صحيح',
      errorSendingSms: 'خطأ – لا يمكن إرسال رسالة نصية القصيرة ‎SMS',
      errorSavingPassenger: 'خطأ في حفظ تفاصيل المسافر',
      uniqueId: 'مُعرّف بهوية المسافر',
      invalidWebsiteUrl: 'رابط تسجيل الدخول للمنظومة غير صالح',
      tooManyTFAAttempts: 'هناك عدد كبير جدًا من محاولات التحقق، يمكنك المحاولة مرة أخرى بعد 60 ثانية',
      errSavingPassengerData: 'حدث خطأ في حفظ بيانات المسافر',
      cancelAcc: 'إلغاء إمكانية الوصول',
      linkHighlighting: 'قم بتأكيد الروابط',
      moreSettings: 'إعدادات إضافية',
      contrast: 'تباين',
      normalContrast: 'تباين اعتيادي',
      highContrast: 'تباين عالي',
      blackAndWhite: 'اسود- ابيض',
      accMenu: 'قائمة بإمكانية الوصول',
      a11y: 'إمكانية الوصول',
      noYitPrivacyAgreement: 'لم يتم العثور على سياسة الخصوصية',
      accountNotExistsOrNotActive: 'الموقع غير نشط أو غير موجود',
      pageNotFound: 'التسجيل من خلال الموقع غير متاح مؤقتاً، يرجى الاتصال بمزود الخدمة.',
      refresh: 'تحديث',
    },
  },
  ru: {
    translation: {
      firstName: 'Имя',
      lastName: 'Фамилия',
      phoneNum: 'Номер телефона',
      email: 'Адрес электронной почты',
      acceptTermsAndCon: 'Я подтверждаю',
      of: 'из',
      ok: 'OK',
      formTitle: 'Регистрационная форма',
      formSubtitle1: 'Мы рады, что вы выбрали нашу службу перевозок',
      formSubtitle2: 'Для регистрации,',
      formSubtitle3: 'Пожалуйста, заполните следующие данные:',
      login: 'Войти',
      continue: 'Продолжить',
      phoneNumber: 'Номер телефона',
      phoneConfirmation: 'Подтвердить номер телефона',
      smsSent: 'Одноразовый код проверки отправлен на номер ',
      enterBelow: 'Просим указать его ниже',
      signupSuccess: 'Регистрация прошла успешно',
      downloadApp: 'Загрузить приложение',
      downloadAppExplanation: 'Для скачивания приложения MyWay из магазина',
      downloadAppExplanationWeb1: 'Для загрузки приложения MyWay отсканируйте QR',
      downloadAppExplanationWeb2: 'или получите ссылку для скачивания на мобильный телефон',
      addAnotherPassenger: 'Зарегистрировать другого пользователя',
      sendAgain: 'Отправить еще раз',
      didNotReceiveCode: 'Не получили код?',
      supportAndContact: 'Контакты и поддержка',
      requiredField: 'Поле, обязательное для заполнения',
      invalidValue: 'Недопустимое значение',
      tooShort: 'Слишком короткое значение',
      atLeast: 'Не менее',
      digits: 'цифр',
      min: 'Минимум',
      mustAcceptPrivacyStatement: 'Принять Политику конфиденциальности',
      privacyStatement: 'Политика конфиденциальности',
      unknownErr: 'Система временно неисправна. Обратитесь в службу поддержки по телефону 08-9464288',
      code: 'Код',
      invalidAuthParams: 'Ссылка для входа в систему недействительна',
      invalidTwoFactorCode: 'Неверный код',
      errorSendingSms: 'Ошибка. СМС-сообщение не может быть отправлено',
      errorSavingPassenger: 'Ошибка при сохранении данных пассажира',
      uniqueId: 'Идентификатор пассажира',
      invalidWebsiteUrl: 'Ссылка для входа в систему недействительна',
      tooManyTFAAttempts: 'Слишком много попыток подтверждения. Повторите попытку через 60 секунд',
      errSavingPassengerData: 'Ошибка при сохранении данных пассажира',
      cancelAcc: 'Отменить специальные возможности',
      linkHighlighting: 'Выделить ссылки',
      moreSettings: 'Больше настроек',
      contrast: 'Контрастность',
      normalContrast: 'Нормальная контрастность',
      highContrast: 'Высокая контрастность',
      blackAndWhite: 'Черно-белый режим',
      accMenu: 'Меню специальных возможностей',
      a11y: 'Специальные возможности',
      noYitPrivacyAgreement: 'Политика конфиденциальности не найдена',
      accountNotExistsOrNotActive: 'Сайт неактивен или не существует',
      pageNotFound: 'Регистрация через сайт временно недоступна, пожалуйста, обратитесь к поставщику услуг.',
      refresh: 'Обновить',
    },
  },
};
export const i18nLanguages = Object.keys(resources) as LangKeys[];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'he', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
