import { createUrl, Endpoints, request } from '../api/utilis';
import { PageConfig } from './types';

type BaseRequestBody = { interfaceGuid: string; accountCode: string };

type GetPageConfigResponseData = PageConfig;
const GetPageConfig = async (body: BaseRequestBody) => {
  const url = createUrl(Endpoints.GetPageConfig);
  return request<GetPageConfigResponseData>(url, body);
};

type SendTFASmsResponseData = unknown;
const SendTFASms = async (body: BaseRequestBody & { mobilePhone: string }) => {
  const url = createUrl(Endpoints.SendTwoFactorSms);
  return request<SendTFASmsResponseData>(url, body);
};

type SavePassengerResponseData = unknown;
const savePassengerWithTwoFa = async (
  body: BaseRequestBody & {
    twoFactorCode: number;
    passengerData: {
      firstName: string;
      lastName: string;
      email: string;
      mobilePhone: string;
      uniqueId: string;
    };
  }
) => {
  const url = createUrl(Endpoints.SavePassengerData);
  return request<SavePassengerResponseData>(url, body);
};

export const api = {
  GetPageConfig,
  SendTFASms,
  savePassengerWithTwoFa,
};
