import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { useAppDispatch } from '../store/hooks';
import Alert from '../components/Alert';
import useAlert from '../hooks/useAlert';
import Home from 'src/screens/Home/Home.index';
import * as React from 'react';

export const DEV_AUTH_PATH = 'http://localhost:3000/';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        <Home />
      </>
    ),
  },
]);

function Routing() {
  const dispatch = useAppDispatch();

  const alertConfig = useAlert();

  return (
    <>
      <RouterProvider router={router} />
      <Alert {...alertConfig.props}>{alertConfig.message}</Alert>
    </>
  );
}

export default Routing;
