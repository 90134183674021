import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import styled from 'styled-components';
import Header from 'src/components/Header';
import { heights } from 'src/styles/commonStyles';
import { mainSliceSelector } from 'src/store/slices/mainSlice';
import { Form } from './components/Form/Form';
import { TwoFa } from './components/TwoFa/TwoFa';
import { Success } from './components/Success/Success';
import { DownloadApp } from './components/DownloadApp/DownloadApp';
import { isMobileSelector } from 'src/store/slices/commonSlice';
import InfoFooter from 'src/components/InfoFooter';
import { useFetchPageConfig } from '../../hooks/useFetchPageConfig';
import { A11yDrawer } from './components/A11yDrawer';
import { PageNotFound } from './components/PageNotFound/PageNotFound';

const innerHeight = window.innerHeight;

const useVerControl = () => {
  React.useEffect(() => {
    const lastVer = localStorage.getItem('lastVer');

    if (lastVer !== process.env.REACT_APP_VER) {
      localStorage.clear();
      localStorage.setItem('lastVer', String(process.env.REACT_APP_VER));
      window.location.reload();
    } else {
      localStorage.setItem('lastVer', String(process.env.REACT_APP_VER));
    }
  }, []);
};

const Home: React.FC = (props: {}) => {
  const dispatch = useAppDispatch();
  useVerControl();
  useFetchPageConfig();

  const stage = useAppSelector((state) => mainSliceSelector(state).ui.stage);
  const isMobile = useAppSelector((state) => isMobileSelector(state));

  const getCenterComp = () => {
    if (stage === 'form') return <Form />;
    if (stage === '2fa') return <TwoFa />;
    if (stage === 'success') return <Success />;
    if (stage === 'downloadApp') return <DownloadApp />;
    if (stage === 'pageNotFound') return <PageNotFound />;

    return null;
  };

  return (
    <HomeContainer className="HomeContainer">
      <A11yDrawer />
      <Header />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: isMobile ? 'flex-start' : 'center',
          marginTop: isMobile ? '20px' : '0px',
          height: `calc(${innerHeight}px - ${heights.header}px - ${heights.infoFooter}px)`,
        }}
      >
        {getCenterComp()}
      </div>
      <InfoFooter />
    </HomeContainer>
  );
};

const HomeContainer = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100vw;
  height: ${innerHeight}px !important;
  min-height: ${innerHeight}px !important;
  min-width: 300px;
  overflow: hidden;
`;

export default Home;
