import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import styled from 'styled-components';
import { MenuItem } from '@mui/material';
import { a11ySelector, mainSliceActions } from 'src/store/slices/mainSlice';
import { useTranslation } from 'react-i18next';
import { useIsRTL } from 'src/hooks/useIsRTL';
import { useOnClickOutside } from 'usehooks-ts';
import { useA11yTheme } from 'src/styles/useA11yTheme';
import A11yLinkHighlight from 'src/components/Icons/A11yLinkHighlight';
import A11yBlackAndWhite from 'src/components/Icons/A11yBlackAndWhite';
import A11yHighContrast from 'src/components/Icons/A11yHighContrast';
import A11yNormalContrast from 'src/components/Icons/A11yNormalContrast';
import useIsInitialMount from 'src/hooks/useIsInitalMount';

export const A11yDrawer: React.FC<{}> = () => {
  const a11y = useAppSelector((state) => a11ySelector(state));

  const isFirstMount = useIsInitialMount();

  React.useEffect(() => {
    if (isFirstMount) return;

    const a11yToSaveToLS: typeof a11y = {
      ...a11y,
      isDrawerOpen: false,
    };

    localStorage.setItem('a11y', JSON.stringify(a11yToSaveToLS));
  }, [a11y.contrast, a11y.isLinkHighlightingActive]);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const isRTL = useIsRTL();
  const { theme } = useA11yTheme();

  const drawerRef = React.useRef(null);

  const handleClose = () => {
    dispatch(mainSliceActions.toggleA11yDrawer(false));
  };

  useOnClickOutside(drawerRef, handleClose);

  const contrast = [
    {
      value: 'blackAndWhite',
      text: t('blackAndWhite'),
      icon: () => <A11yBlackAndWhite />,
    },
    {
      value: 'high',
      text: t('highContrast'),
      icon: () => <A11yHighContrast />,
    },
    {
      value: 'normal',
      text: t('normalContrast'),
      icon: () => <A11yNormalContrast />,
    },
  ] as const;

  const menuItemStyle = {
    height: '50px',
    '&:hover': {
      border: `2px solid ${theme.colors.black}`,
    },
    '&.Mui-selected': {
      border: `2px solid ${theme.colors.black}`,
    },
  } as const;

  return a11y.isDrawerOpen ? (
    <Container ref={drawerRef}>
      <div
        style={{
          height: '60px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 12px',
        }}
      >
        <span style={{ fontSize: '22px' }}>{t('accMenu')}</span>
        <CloseButton onClick={() => handleClose()}>X</CloseButton>
      </div>
      <A11yHeader isRTL={isRTL}>{t('contrast')}</A11yHeader>
      {contrast.map((item) => {
        return (
          <MenuItem
            selected={a11y.contrast === item.value}
            key={item.value}
            onClick={() => {
              dispatch(mainSliceActions.setA11yContrast(item.value));
            }}
            sx={menuItemStyle}
          >
            <MenuItemContentCont>
              {item.icon()}
              {item.text}
            </MenuItemContentCont>
          </MenuItem>
        );
      })}
      <A11yHeader isRTL={isRTL}>{t('moreSettings')}</A11yHeader>
      <MenuItem
        selected={a11y.isLinkHighlightingActive}
        onClick={() => {
          dispatch(mainSliceActions.setA11yLinkHighlighting(!a11y.isLinkHighlightingActive));
        }}
        sx={menuItemStyle}
      >
        <MenuItemContentCont>
          <A11yLinkHighlight />
          {t('linkHighlighting')}
        </MenuItemContentCont>
      </MenuItem>
      <MenuItem
        onClick={() => {
          dispatch(mainSliceActions.resetA11y());
        }}
        sx={menuItemStyle}
      >
        <MenuItemContentCont>
          <span style={{ width: '20px', textAlign: 'center', fontSize: '16px' }}>X</span>
          {t('cancelAcc')}
        </MenuItemContentCont>
      </MenuItem>
    </Container>
  ) : null;
};

const Container = styled.div<{ isRTL?: boolean }>`
  position: absolute;
  width: 300px;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.textColors.primaryTextColor};
  z-index: 999;
  left: 0;
  height: 100%;
  box-shadow: 2px 0px 25px #00000042;
`;

const A11yHeader = styled.div<{ isRTL: boolean }>`
  height: 50px;
  background: ${(props) => props.theme.colors.lightGray};
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: ${(props) => (props.isRTL ? '0 12px 0 0' : '0 0 0 12px')};
  color: black;
`;

const MenuItemContentCont = styled.div`
  display: flex;
  gap: 8px;
`;

const CloseButton = styled.button`
  border: none;
  background: none;
  font-size: 20px;
  color: ${(props) => props.theme.colors.black};
`;
