import React from 'react';
import { BaseCard, CardSubtitle, CardTitle } from 'src/components/BaseCard';
import styled from 'styled-components';
import { PageNotFoundImage } from './PageNotFoundImage';
import { useTranslation } from 'react-i18next';
import { BaseButton } from 'src/components/BaseButton';
import { useAppSelector } from 'src/store/hooks';
import { isMobileSelector } from 'src/store/slices/commonSlice';

export const PageNotFound: React.FC<{}> = () => {
  const { t } = useTranslation();
  const isMobile = useAppSelector((state) => isMobileSelector(state));
  const errCode = useAppSelector((state) => state.main.api.GetPageConfig.errCode);

  return (
    <BaseCard style={{}}>
      <div>
        <CardTitle>{t('pageNotFound')}</CardTitle>
        <CardSubtitle>
          ( {t('code')} {errCode} )
        </CardSubtitle>
      </div>

      <div style={isMobile ? imgStyle.mobile : imgStyle.web}>
        <PageNotFoundImage />
      </div>

      <div style={{ marginTop: '24px', width: '100%' }}>
        <BaseButton
          text={t('refresh')}
          onClick={() => {
            window.location.reload();
          }}
        />
      </div>
    </BaseCard>
  );
};

const imgStyle = {
  mobile: { width: '237px' },
  web: {},
};
