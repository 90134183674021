import React from 'react';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import { useAppDispatch } from 'src/store/hooks';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { mainSliceActions } from 'src/store/slices/mainSlice';
import BaseTooltip from './BaseTooltip';

const BaseIconBtn: React.FC<{
  children: React.ReactNode;
  tooltipTitle: string;
  onClick: () => void;
  IconButtonProps: Partial<React.ComponentProps<typeof IconButton>>;
}> = ({ children, tooltipTitle, onClick, IconButtonProps = {} }) => {
  return (
    <BaseTooltip title={tooltipTitle}>
      <IconButton {...IconButtonProps} onClick={onClick} size="small">
        {children}
      </IconButton>
    </BaseTooltip>
  );
};

export const AccessibilityMenu: React.FC<{}> = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(mainSliceActions.toggleA11yDrawer(true));
  };

  return (
    <div style={{ position: 'relative', width: '50px' }}>
      <BaseIconBtn onClick={handleClick} tooltipTitle={t('a11y')} IconButtonProps={{ sx: {} }}>
        <AccessibilityIcon />
      </BaseIconBtn>
    </div>
  );
};
