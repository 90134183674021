import React from 'react';
import BaseTextField from 'src/components/BaseTextField';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { mainSliceActions } from 'src/store/slices/mainSlice';
import { Link } from '../../../../components/Link';
import { useForm } from './useForm';
import { Card, CardMainTitle, CardSubtitle, CardTitle } from '../../../../components/BaseCard';
import { InputConfig } from './types.form';
import { emailRegex, isNumericStr, mobilePhoneRegex } from 'src/utils/utilis';
import { CheckboxWithLabel } from 'src/components/CheckboxWithLabel';
import { useTranslation } from 'react-i18next';
import { BaseButton } from 'src/components/BaseButton';
import { CircularProgress } from '@mui/material';
import { Endpoints } from 'src/api/utilis';
import { useIsRTL } from 'src/hooks/useIsRTL';
import styled from 'styled-components';
import { useHasVerticalScroll } from '../../../../hooks/useHasVerticalScroll';
import { useDispatchAlert } from 'src/hooks/useAlert';
import i18n from 'src/i18n';
import { useScrollToInputWithErrors } from './useScrollToInputWithErrors';
import useEnterKey from 'src/hooks/useEnterKey';
import { flexCenter } from 'src/styles/commonStyles';
import { isMobileSelector } from 'src/store/slices/commonSlice';

export const Form: React.FC<{}> = () => {
  const isAuthLoading = useAppSelector(
    (state) => state.main.api[Endpoints.GetPageConfig].status === 'loading'
  );
  const { accountPageConfig, yitPrivacyAgreementUrl } =
    useAppSelector((state) => state.main.api[Endpoints.GetPageConfig].data) || {};

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isRTL = useIsRTL();
  const { divRef, hasVerticalScroll } = useHasVerticalScroll([accountPageConfig]);
  const dispatchAlert = useDispatchAlert();
  const language = useAppSelector((state) => state.main.ui.language);
  const isMobile = useAppSelector((state) => isMobileSelector(state));


  const textInputs = React.useMemo(() => {
    const output = [
      {
        fieldKey: 'firstName',
        label: t('firstName'),
        inputProps: {},
        validation: {
          maxLength: 20,
          required: true,
        },
      },
      {
        fieldKey: 'lastName',
        label: t('lastName'),
        inputProps: {},
        validation: {
          required: true,
          maxLength: 20,
        },
      },
      {
        fieldKey: 'phoneNum',
        label: t('phoneNum'),
        inputProps: {},
        validation: {
          maxLength: 10,
          type: 'number',
          required: true,
          minLen: 10,
          regex: mobilePhoneRegex,
        },
      },
    ] as InputConfig[];

    if (accountPageConfig?.showUniqueIdInput) {
      output.push({
        fieldKey: 'uniqueId',
        label: accountPageConfig.uniqueIdInputLabels[language] || t('uniqueId'),
        inputProps: {},
        validation: {
          maxLength: 10,
          type: 'number',
          required: true,
        },
      });
    }

    if (accountPageConfig?.showEmailInput) {
      output.push({
        fieldKey: 'email',
        label: t('email'),
        inputProps: { type: 'email' },
        validation: {
          maxLength: 256,
          regex: emailRegex,
          required: true,
        },
      });
    }

    return output;
  }, [t, accountPageConfig]);

  const { values, errors, onSubmit, isLoading } = useForm({ textInputs });

  const onCheckboxClick = (fieldKey: 'acceptedYitPrivacyAgreement' | 'acceptedSitePrivacyAgreement') => {
    const updatedChecked = !values[fieldKey];

    dispatch(
      mainSliceActions.updateFieldValue({
        field: fieldKey,
        value: updatedChecked,
      })
    );

    if (updatedChecked) {
      dispatch(mainSliceActions.resetFieldErrors(fieldKey));
      return;
    }
  };

  const inputsRefs = React.useRef<any[]>([]);
  const yitPrivacyPolicyInputRefIndex = textInputs.length;
  const sitePrivacyPolicyInputRefIndex = textInputs.length + 1;

  const { triggerScrollToFieldsWithErrors } = useScrollToInputWithErrors({
    errors,
    inputsRefs,
    sitePrivacyPolicyInputRefIndex,
    textInputs,
    yitPrivacyPolicyInputRefIndex,
  });

  const isFormDisabled = !accountPageConfig;

  return (
    <Card>
      {isAuthLoading ? (
        <div style={{ ...flexCenter, height: '100%' }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <CardMainTitle>עמוד רישום נוסעים</CardMainTitle>
          <CardTitle>
            {accountPageConfig?.accountName}
          </CardTitle>
          <CardSubtitle withMarginBottom={false}>{t('formSubtitle1')}</CardSubtitle>
          <CardSubtitle withMarginBottom={false}>{t('formSubtitle2')}</CardSubtitle>
          <CardSubtitle withMarginBottom={true}>{t('formSubtitle3')}</CardSubtitle>
          <FieldsCont ref={divRef} isRTL={isRTL} hasVerticalScroll={hasVerticalScroll}>
            <div style={styles.textFieldsCont}>
              {textInputs.map((ti, i) => {
                return (
                  <BaseTextField
                    key={ti.fieldKey}
                    label={ti.label}
                    containerRef={inputsRefs.current[i]}
                    inputProps={{
                      disabled: isFormDisabled,
                      value: values[ti.fieldKey],
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value;

                        if (ti.validation.type === 'number' && !isNumericStr(value)) return;

                        if (ti.validation.maxLength && value.length > ti.validation.maxLength) return;

                        dispatch(mainSliceActions.resetFieldErrors(ti.fieldKey));

                        dispatch(
                          mainSliceActions.updateFieldValue({
                            field: ti.fieldKey,
                            value,
                          })
                        );
                      },
                      error: errors[ti.fieldKey]?.length ? true : false,
                      ...ti.inputProps,
                    }}
                    errors={errors[ti.fieldKey]}
                  />
                );
              })}
            </div>
            <div style={{ flex: 1, paddingTop: '12px' }}>
              <CheckboxWithLabel
                isDisabled={isFormDisabled}
                containerRef={inputsRefs.current[yitPrivacyPolicyInputRefIndex]}
                id="1"
                error={
                  errors.acceptedYitPrivacyAgreement?.length ? errors.acceptedYitPrivacyAgreement[0] : ''
                }
                onClick={() => {
                  if (!yitPrivacyAgreementUrl) {
                    dispatchAlert('error', t('noYitPrivacyAgreement'));
                    return;
                  }

                  onCheckboxClick('acceptedYitPrivacyAgreement');
                }}
                checked={values.acceptedYitPrivacyAgreement}
                label={() => {
                  return (
                    <div style={{
                      display: 'flex',
                      flexWrap: 'wrap', // Allows the items to wrap onto multiple lines as needed.
                      alignItems: 'center', // Keeps items aligned center vertically.
                      gap: '4px',
                      fontSize: isMobile ? '12px' : 'inherit' // Adjusts font size for mobile.
                    }}>
                      <span >{t('acceptTermsAndCon')}</span>
                      <Link
                        href={yitPrivacyAgreementUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {t('privacyStatement')}
                      </Link>
                      <span>{t('of')} Y-IT</span>
                    </div>
                  );
                }}
              />
              {accountPageConfig?.sitePrivacyAgreementUrl ? (
                <CheckboxWithLabel
                  isDisabled={isFormDisabled}
                  containerRef={inputsRefs.current[sitePrivacyPolicyInputRefIndex]}
                  id="2"
                  error={
                    errors.acceptedSitePrivacyAgreement?.length ? errors.acceptedSitePrivacyAgreement[0] : ''
                  }
                  onClick={() => {
                    onCheckboxClick('acceptedSitePrivacyAgreement');
                  }}
                  checked={values.acceptedSitePrivacyAgreement}
                  label={() => {
                    return (
                      <div style={{
                        display: 'flex',
                        flexWrap: 'wrap', // Allows the items to wrap onto multiple lines as needed.
                        alignItems: 'center', // Keeps items aligned center vertically.
                        gap: '4px',
                        fontSize: isMobile ? '12px' : 'inherit' // Adjusts font size for mobile.
                      }}>
                        <span>{t('acceptTermsAndCon')}</span>
                        <Link
                          style={{}}
                          href={accountPageConfig.sitePrivacyAgreementUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          {t('privacyStatement')}
                        </Link>
                        <span>
                          {' '}
                          &nbsp;{t('of')} {accountPageConfig.accountName}
                        </span>
                      </div>
                    );
                  }}
                />
              ) : null}
            </div>
          </FieldsCont>

          <BaseButton
            ButtonProps={{ disabled: isFormDisabled }}
            onClick={() => {
              onSubmit();
              // scrollToFieldsWithErrors();
              console.log('inputsRefs', inputsRefs);
              triggerScrollToFieldsWithErrors();
            }}
            text={t('continue')}
            isLoading={isLoading}
          />
        </>
      )}
    </Card>
  );
};

const FieldsCont = styled.div<{ isRTL: boolean; hasVerticalScroll: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-bottom: 16px;
  justify-content: space-between;

  // Conditional padding based on RTL and vertical scrollbar presence
  ${(props) => (props.isRTL ? 'padding-left' : 'padding-right')}: ${(props) =>
    props.hasVerticalScroll ? '12px' : 0};
`;

const styles = {
  textFieldsCont: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
  },
} as const;
