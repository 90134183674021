import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { api } from 'src/api/api';
import { useDispatchAlert } from 'src/hooks/useAlert';
import { useTranslation } from 'react-i18next';
import { LangKeys, mainSliceActions } from 'src/store/slices/mainSlice';
import { useAppDispatch } from 'src/store/hooks';
import { getErrWithCode } from 'src/utils/utilis';
import { Endpoints } from 'src/api/utilis';
import { authSliceActions } from 'src/store/slices/authSlice';
import i18n, { i18nLanguages } from 'src/i18n';
import { getErrMsgWithCode, useErrorsTextsByCode } from './useErrorsTextsByCode';
import { useLanguageUtils } from './useLanguageUtils';
import { LocalErrCodes } from 'src/api/types';

// export const getErrWithCode = (
//   err: string,
//   code: number,
//   options?: {
//       withPrefix?: boolean;
//       withSuffix?: boolean;
//   }
// ) => {
//   return `${options?.withPrefix ? 'שגיאה - ' : ''}
//   ${err}
//   ${options?.withSuffix ? '. ניתן ליצור קשר עם התמיכה בטלפון 08-9464288' : ''}
//     ( קוד שגיאה ${code} )`;
// };

export const useFetchPageConfig = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const dispatchAlert = useDispatchAlert();
  const [searchParams] = useSearchParams();
  const errorsTextByCode = useErrorsTextsByCode();
  const { setLanguage } = useLanguageUtils();

  const fetched = React.useRef(false);

  React.useEffect(() => {
    if (fetched.current) return;

    fetched.current = true;

    const interfaceGuid = searchParams.get('interfaceGuid');
    const accountCode = searchParams.get('accountCode');

    const handleErr = (errMsg: string, errCode: number) => {
      dispatch(
        mainSliceActions.setApiRequestFailed({
          key: Endpoints.GetPageConfig,
          errMsg,
          errCode,
        })
      );
      dispatch(mainSliceActions.setStage('pageNotFound'));
    };

    if (!interfaceGuid || !accountCode) {
      handleErr('Missing url params', LocalErrCodes.InvalidAuthParams);
      return;
    }

    dispatch(mainSliceActions.setApiRequestLoading({ key: Endpoints.GetPageConfig }));

    const authParams = { interfaceGuid, accountCode };

    api
      .GetPageConfig(authParams)
      .then((r) => {
        if (r.isSuccess === false) {
          const errMsg = errorsTextByCode.getErrMsg('getPageConfigErrors', r.code);

          handleErr(errMsg, r.code);
          return;
        }

        dispatch(authSliceActions.setAuthParams(authParams));

        dispatch(
          mainSliceActions.setApiRequestSuccess({
            key: Endpoints.GetPageConfig,
            data: r.data,
          })
        );

        if (i18nLanguages.includes(r.data?.accountPageConfig.defaultLanguage as any)) {
          setLanguage(r.data?.accountPageConfig.defaultLanguage as LangKeys);
        }

        console.log('r', r);
      })
      .catch((err) => {
        console.log(err);
        handleErr(err.message, 999);
      });
  }, []);
};
