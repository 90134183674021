import { useAppSelector } from 'src/store/hooks';
import { formSelector, mainSliceActions } from 'src/store/slices/mainSlice';
import { api } from 'src/api/api';
import { authRootSelector } from 'src/store/slices/authSlice';
import { useDispatchAlert } from './useAlert';
import { useTranslation } from 'react-i18next';
import { getErrWithCode } from 'src/utils/utilis';
import { useErrorsTextsByCode } from './useErrorsTextsByCode';
import { Endpoints } from 'src/api/utilis';
import React from 'react';

export const useSendTFASms = () => {
  const { t } = useTranslation();

  const values = useAppSelector((state) => formSelector(state).values);
  const authParams = useAppSelector((state) => authRootSelector(state).params);

  const dispatchAlert = useDispatchAlert();
  const errorsTextByCode = useErrorsTextsByCode();

  const [isLoading, setIsLoading] = React.useState(false);

  const send = async () => {
    try {
      setIsLoading(true);

      const response = await api.SendTFASms({ ...authParams, mobilePhone: values.phoneNum });

      if (!response.isSuccess) {
        dispatchAlert('error', errorsTextByCode.getErrMsg('sendSmsErrors', response.code));
      }

      return response;
    } catch (error) {
      dispatchAlert('error', t('errorSendingSms'));
      console.log(error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return { send, isLoading };
};
