/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

function GetOnAppStore({ width = '28', height = '28', color = '#494949' }) {
  return (
    <svg width="159" height="48" viewBox="0 0 159 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10356_139191)">
        <path
          d="M152.648 0H6.35185C3.13333 0 0.5 2.7 0.5 6V42C0.5 45.3 3.13333 48 6.35185 48H152.648C155.867 48 158.5 45.3 158.5 42V6C158.5 2.7 155.867 0 152.648 0ZM152.648 0.960003C155.359 0.960003 157.564 3.22128 157.564 6V42C157.564 44.7792 155.359 47.0405 152.648 47.0405H6.35185C3.64174 47.0405 1.4363 44.7792 1.4363 42V6C1.4363 3.22128 3.64174 0.960003 6.35185 0.960003H152.648Z"
          fill="black"
        />
        <path
          d="M38.6912 24.3688C38.7043 23.3272 38.9744 22.306 39.4764 21.4001C39.9783 20.4942 40.6958 19.7331 41.5622 19.1875C41.0118 18.3825 40.2858 17.7199 39.4417 17.2525C38.5977 16.7851 37.6589 16.5258 36.6998 16.495C34.6541 16.2751 32.6708 17.7489 31.6283 17.7489C30.5656 17.7489 28.9605 16.5169 27.2321 16.5533C26.1141 16.5903 25.0246 16.9233 24.0696 17.5199C23.1146 18.1164 22.3268 18.9562 21.7829 19.9573C19.4268 24.1357 21.1842 30.2766 23.4412 33.6541C24.5704 35.308 25.8901 37.1554 27.617 37.0899C29.3068 37.0181 29.9379 35.9862 31.9777 35.9862C33.9985 35.9862 34.5906 37.0899 36.3525 37.0483C38.1658 37.0181 39.3082 35.387 40.3978 33.7175C41.2091 32.5391 41.8335 31.2366 42.2477 29.8584C41.1942 29.4021 40.2952 28.6381 39.6628 27.6619C39.0303 26.6857 38.6924 25.5404 38.6912 24.3688Z"
          fill="black"
        />
        <path
          d="M35.3614 14.2729C36.3501 13.0572 36.8372 11.4946 36.7192 9.91699C35.2088 10.0795 33.8135 10.8189 32.8115 11.988C32.3216 12.5591 31.9464 13.2235 31.7073 13.9432C31.4682 14.663 31.37 15.4239 31.4182 16.1826C32.1737 16.1905 32.9211 16.0228 33.6041 15.692C34.2872 15.3612 34.888 14.876 35.3614 14.2729Z"
          fill="black"
        />
        <path
          d="M58.9641 33.0672H53.4188L52.0871 37.095H49.7383L54.9907 22.1934H57.431L62.6835 37.095H60.2947L58.9641 33.0672ZM53.9931 31.2086H58.3887L56.2218 24.6719H56.1611L53.9931 31.2086Z"
          fill="black"
        />
        <path
          d="M74.0264 31.6637C74.0264 35.0399 72.2622 37.209 69.6 37.209C68.9256 37.2452 68.2549 37.086 67.6647 36.7499C67.0745 36.4137 66.5886 35.9141 66.2627 35.3082H66.2123V40.6895H64.0352V26.2309H66.1425V28.0379H66.1826C66.5235 27.435 67.0175 26.938 67.6122 26.5997C68.2069 26.2615 68.88 26.0948 69.5599 26.1172C72.2519 26.1172 74.0264 28.2969 74.0264 31.6637ZM71.7886 31.6637C71.7886 29.4641 70.6788 28.018 68.9856 28.018C67.3221 28.018 66.2032 29.4946 66.2032 31.6637C66.2032 33.8528 67.3221 35.3188 68.9856 35.3188C70.6788 35.3188 71.7885 33.8833 71.7885 31.6637H71.7886Z"
          fill="black"
        />
        <path
          d="M85.7027 31.6637C85.7027 35.0399 83.938 37.209 81.2757 37.209C80.6013 37.2452 79.9306 37.086 79.3404 36.7499C78.7502 36.4137 78.2644 35.9141 77.9385 35.3082H77.8881V40.6895H75.7109V26.2309H77.8183V28.0379H77.8584C78.1992 27.435 78.6933 26.938 79.288 26.5997C79.8827 26.2615 80.5558 26.0948 81.2357 26.1172C83.9277 26.1172 85.7027 28.2969 85.7027 31.6637ZM83.4644 31.6637C83.4644 29.4641 82.3546 28.018 80.6614 28.018C78.9979 28.018 77.879 29.4946 77.879 31.6637C77.879 33.8528 78.9979 35.3188 80.6614 35.3188C82.3546 35.3188 83.4644 33.8833 83.4644 31.6637Z"
          fill="black"
        />
        <path
          d="M93.4162 32.9434C93.5775 34.4212 94.979 35.3914 96.8942 35.3914C98.7293 35.3914 100.05 34.4211 100.05 33.0887C100.05 31.932 99.2533 31.2395 97.3679 30.7649L95.4824 30.2996C92.811 29.6387 91.5708 28.359 91.5708 26.2824C91.5708 23.7113 93.7583 21.9453 96.8633 21.9453C99.9386 21.9453 102.046 23.7113 102.117 26.2824H99.9191C99.7876 24.7953 98.5874 23.8977 96.8336 23.8977C95.0797 23.8977 93.8796 24.8059 93.8796 26.1277C93.8796 27.1813 94.6461 27.8012 96.5212 28.2757L98.1241 28.6789C101.109 29.4019 102.348 30.6301 102.348 32.8097C102.348 35.5976 100.181 37.3437 96.7328 37.3437C93.5065 37.3437 91.3282 35.6386 91.1875 32.9433L93.4162 32.9434Z"
          fill="black"
        />
        <path
          d="M107.05 23.6611V26.2322H109.067V27.9982H107.05V33.9877C107.05 34.9182 107.454 35.3518 108.341 35.3518C108.58 35.3475 108.819 35.3303 109.057 35.3002V37.0556C108.658 37.1319 108.253 37.1665 107.847 37.1588C105.7 37.1588 104.863 36.3326 104.863 34.2255V27.9982H103.32V26.2322H104.862V23.6611H107.05Z"
          fill="black"
        />
        <path
          d="M110.234 31.665C110.234 28.2467 112.2 26.0986 115.265 26.0986C118.34 26.0986 120.297 28.2466 120.297 31.665C120.297 35.0928 118.35 37.2314 115.265 37.2314C112.18 37.2314 110.234 35.0928 110.234 31.665ZM118.078 31.665C118.078 29.3201 117.029 27.9361 115.265 27.9361C113.501 27.9361 112.452 29.3307 112.452 31.665C112.452 34.0193 113.501 35.3928 115.265 35.3928C117.029 35.3928 118.078 34.0193 118.078 31.665H118.078Z"
          fill="black"
        />
        <path
          d="M122.09 26.2308H124.166V28.08H124.217C124.357 27.5025 124.688 26.9921 125.152 26.6346C125.617 26.2771 126.187 26.0944 126.768 26.1171C127.019 26.1162 127.269 26.1441 127.514 26.2003V28.2863C127.197 28.1871 126.867 28.1416 126.536 28.1515C126.219 28.1384 125.904 28.1955 125.611 28.319C125.319 28.4424 125.056 28.6293 124.84 28.8668C124.625 29.1042 124.462 29.3867 124.363 29.6947C124.264 30.0027 124.231 30.329 124.267 30.6511V37.0953H122.09L122.09 26.2308Z"
          fill="black"
        />
        <path
          d="M137.552 33.9057C137.259 35.8779 135.384 37.2314 132.985 37.2314C129.9 37.2314 127.984 35.1139 127.984 31.7166C127.984 28.3088 129.91 26.0986 132.894 26.0986C135.828 26.0986 137.674 28.1635 137.674 31.4576V32.2217H130.182V32.3564C130.148 32.7563 130.196 33.1591 130.326 33.5381C130.455 33.9171 130.661 34.2636 130.931 34.5547C131.201 34.8458 131.528 35.0747 131.891 35.2262C132.254 35.3778 132.644 35.4485 133.035 35.4338C133.55 35.4832 134.066 35.3611 134.508 35.0858C134.949 34.8104 135.292 34.3966 135.485 33.9057L137.552 33.9057ZM130.192 30.6631H135.495C135.515 30.3036 135.462 29.9438 135.339 29.6063C135.216 29.2689 135.027 28.961 134.783 28.7021C134.538 28.4432 134.244 28.2388 133.919 28.1017C133.594 27.9647 133.245 27.8979 132.894 27.9057C132.539 27.9035 132.188 27.9733 131.86 28.111C131.531 28.2487 131.233 28.4515 130.982 28.7079C130.731 28.9643 130.532 29.269 130.396 29.6046C130.261 29.9402 130.192 30.2999 130.192 30.6631V30.6631Z"
          fill="black"
        />
        <path
          d="M53.7203 10.9779C54.1767 10.9443 54.6348 11.0149 55.0613 11.1847C55.4879 11.3544 55.8724 11.6189 56.187 11.9593C56.5017 12.2996 56.7387 12.7073 56.8809 13.1528C57.0231 13.5983 57.0671 14.0706 57.0095 14.5357C57.0095 16.8232 55.8025 18.1381 53.7203 18.1381H51.1953V10.9779H53.7203ZM52.281 17.1255H53.599C53.9252 17.1454 54.2516 17.0906 54.5545 16.9651C54.8573 16.8396 55.1291 16.6464 55.3499 16.3998C55.5707 16.1531 55.7351 15.8591 55.831 15.5391C55.9269 15.2192 55.9519 14.8814 55.9043 14.5503C55.9485 14.2205 55.9209 13.8847 55.8235 13.5671C55.7262 13.2495 55.5614 12.9579 55.3411 12.7132C55.1208 12.4685 54.8503 12.2767 54.5491 12.1516C54.248 12.0266 53.9235 11.9713 53.599 11.9897H52.281V17.1255Z"
          fill="black"
        />
        <path
          d="M58.2344 15.4345C58.2013 15.0794 58.2409 14.7211 58.3509 14.3826C58.4608 14.0442 58.6386 13.733 58.8728 13.469C59.1071 13.205 59.3926 12.9941 59.7111 12.8497C60.0295 12.7054 60.374 12.6309 60.7222 12.6309C61.0705 12.6309 61.4149 12.7054 61.7334 12.8497C62.0519 12.9941 62.3374 13.205 62.5716 13.469C62.8059 13.733 62.9836 14.0442 63.0936 14.3826C63.2035 14.7211 63.2432 15.0794 63.21 15.4345C63.2438 15.79 63.2046 16.1488 63.095 16.4878C62.9853 16.8268 62.8076 17.1386 62.5733 17.4031C62.339 17.6676 62.0533 17.879 61.7345 18.0236C61.4157 18.1682 61.0709 18.243 60.7222 18.243C60.3735 18.243 60.0287 18.1682 59.7099 18.0236C59.3911 17.879 59.1054 17.6676 58.8711 17.4031C58.6368 17.1386 58.4591 16.8268 58.3495 16.4878C58.2398 16.1488 58.2006 15.79 58.2344 15.4345ZM62.1391 15.4345C62.1391 14.2632 61.6254 13.5783 60.7239 13.5783C59.819 13.5783 59.3099 14.2632 59.3099 15.4345C59.3099 16.6152 59.819 17.2949 60.7239 17.2949C61.6255 17.2949 62.1392 16.6105 62.1392 15.4345H62.1391Z"
          fill="black"
        />
        <path
          d="M69.8271 18.1379H68.7471L67.6568 14.1582H67.5744L66.4887 18.1379H65.419L63.9648 12.7344H65.0208L65.9658 16.8576H66.0436L67.1282 12.7344H68.127L69.2116 16.8576H69.294L70.2344 12.7344H71.2755L69.8271 18.1379Z"
          fill="black"
        />
        <path
          d="M72.4961 12.7336H73.4983V13.592H73.5761C73.7081 13.2837 73.9307 13.0253 74.2129 12.8527C74.4951 12.6802 74.8228 12.6021 75.1504 12.6293C75.407 12.6096 75.6648 12.6492 75.9045 12.7453C76.1442 12.8414 76.3597 12.9916 76.535 13.1846C76.7104 13.3776 76.8411 13.6086 76.9175 13.8603C76.9939 14.1121 77.014 14.3783 76.9763 14.6391V18.1371H75.9352V14.9069C75.9352 14.0385 75.5668 13.6067 74.7968 13.6067C74.6226 13.5984 74.4486 13.6288 74.2869 13.6958C74.1251 13.7628 73.9794 13.8648 73.8598 13.9948C73.7401 14.1249 73.6494 14.2799 73.5937 14.4493C73.538 14.6186 73.5187 14.7983 73.5372 14.976V18.1372H72.4961L72.4961 12.7336Z"
          fill="black"
        />
        <path d="M78.6367 10.625H79.6778V18.1379H78.6367V10.625Z" fill="black" />
        <path
          d="M81.125 15.4327C81.0919 15.0775 81.1316 14.7192 81.2415 14.3807C81.3515 14.0422 81.5293 13.731 81.7636 13.467C81.9978 13.2031 82.2834 12.9921 82.6019 12.8478C82.9204 12.7035 83.2648 12.6289 83.6131 12.6289C83.9614 12.6289 84.3058 12.7035 84.6243 12.8478C84.9428 12.9921 85.2284 13.2031 85.4626 13.467C85.6969 13.731 85.8747 14.0422 85.9847 14.3807C86.0946 14.7192 86.1343 15.0775 86.1012 15.4327C86.1349 15.7881 86.0957 16.1469 85.986 16.486C85.8763 16.825 85.6986 17.1368 85.4643 17.4012C85.23 17.6657 84.9442 17.8771 84.6254 18.0217C84.3066 18.1664 83.9618 18.2411 83.6131 18.2411C83.2644 18.2411 82.9196 18.1664 82.6008 18.0217C82.282 17.8771 81.9962 17.6657 81.7619 17.4012C81.5276 17.1368 81.3499 16.825 81.2402 16.486C81.1305 16.1469 81.0913 15.7881 81.125 15.4327ZM85.0297 15.4327C85.0297 14.2614 84.516 13.5764 83.6145 13.5764C82.7096 13.5764 82.2005 14.2614 82.2005 15.4327C82.2005 16.6133 82.7096 17.293 83.6145 17.293C84.5161 17.293 85.0297 16.6086 85.0297 15.4327H85.0297Z"
          fill="black"
        />
        <path
          d="M87.1953 16.6105C87.1953 15.6379 87.9024 15.0771 89.1574 14.9975L90.5864 14.9131V14.4467C90.5864 13.876 90.218 13.5537 89.5063 13.5537C88.9252 13.5537 88.5224 13.7723 88.4069 14.1543H87.399C87.5054 13.2262 88.3577 12.6309 89.5544 12.6309C90.877 12.6309 91.6229 13.3053 91.6229 14.4467V18.1387H90.6207V17.3793H90.5383C90.3711 17.6517 90.1363 17.8737 89.8579 18.0228C89.5796 18.1719 89.2675 18.2426 88.9538 18.2277C88.7323 18.2513 88.5085 18.2271 88.2967 18.1567C88.0849 18.0862 87.8899 17.9711 87.7243 17.8187C87.5586 17.6664 87.4259 17.4801 87.3348 17.272C87.2437 17.0639 87.1962 16.8386 87.1953 16.6105ZM90.5864 16.1488V15.6971L89.2981 15.7814C88.5716 15.8312 88.2421 16.0844 88.2421 16.5607C88.2421 17.0471 88.654 17.3301 89.2204 17.3301C89.3863 17.3473 89.5539 17.3301 89.7133 17.2796C89.8726 17.2291 90.0204 17.1463 90.1478 17.0361C90.2753 16.9258 90.3798 16.7905 90.4551 16.6381C90.5305 16.4857 90.5751 16.3193 90.5864 16.1488Z"
          fill="black"
        />
        <path
          d="M92.9922 15.4338C92.9922 13.7264 93.8491 12.6447 95.1819 12.6447C95.5116 12.6292 95.8388 12.7101 96.1253 12.8779C96.4118 13.0458 96.6457 13.2937 96.7997 13.5927H96.8775V10.625H97.9186V18.1379H96.9209V17.2842H96.8386C96.6727 17.5812 96.4306 17.826 96.1386 17.9922C95.8466 18.1584 95.5158 18.2395 95.182 18.2269C93.84 18.227 92.9922 17.1453 92.9922 15.4338ZM94.0677 15.4338C94.0677 16.5799 94.5951 17.2696 95.4772 17.2696C96.3546 17.2696 96.8969 16.57 96.8969 15.4385C96.8969 14.3123 96.3489 13.6028 95.4772 13.6028C94.6008 13.6028 94.0676 14.2971 94.0676 15.4338H94.0677Z"
          fill="black"
        />
        <path
          d="M102.227 15.4345C102.193 15.0794 102.233 14.7211 102.343 14.3826C102.453 14.0442 102.631 13.733 102.865 13.469C103.099 13.205 103.385 12.9941 103.703 12.8497C104.022 12.7054 104.366 12.6309 104.714 12.6309C105.063 12.6309 105.407 12.7054 105.726 12.8497C106.044 12.9941 106.33 13.205 106.564 13.469C106.798 13.733 106.976 14.0442 107.086 14.3826C107.196 14.7211 107.235 15.0794 107.202 15.4345C107.236 15.79 107.197 16.1488 107.087 16.4878C106.978 16.8268 106.8 17.1386 106.566 17.4031C106.331 17.6676 106.046 17.879 105.727 18.0236C105.408 18.1682 105.063 18.243 104.714 18.243C104.366 18.243 104.021 18.1682 103.702 18.0236C103.383 17.879 103.098 17.6676 102.863 17.4031C102.629 17.1386 102.451 16.8268 102.342 16.4878C102.232 16.1488 102.193 15.79 102.227 15.4345ZM106.131 15.4345C106.131 14.2632 105.618 13.5783 104.716 13.5783C103.811 13.5783 103.302 14.2632 103.302 15.4345C103.302 16.6152 103.811 17.2949 104.716 17.2949C105.618 17.2949 106.131 16.6105 106.131 15.4345Z"
          fill="black"
        />
        <path
          d="M108.598 12.7336H109.6V13.592H109.678C109.81 13.2837 110.032 13.0253 110.314 12.8527C110.597 12.6802 110.924 12.6021 111.252 12.6293C111.509 12.6096 111.766 12.6492 112.006 12.7453C112.246 12.8414 112.461 12.9916 112.637 13.1846C112.812 13.3776 112.943 13.6086 113.019 13.8603C113.095 14.1121 113.116 14.3783 113.078 14.6391V18.1371H112.037V14.9069C112.037 14.0385 111.668 13.6067 110.898 13.6067C110.724 13.5984 110.55 13.6288 110.388 13.6958C110.227 13.7628 110.081 13.8648 109.961 13.9948C109.842 14.1249 109.751 14.2799 109.695 14.4493C109.64 14.6186 109.62 14.7983 109.639 14.976V18.1372H108.598V12.7336Z"
          fill="black"
        />
        <path
          d="M118.962 11.3887V12.7586H120.105V13.6568H118.962V16.4354C118.962 17.0014 119.189 17.2492 119.708 17.2492C119.84 17.2488 119.973 17.2406 120.105 17.2246V18.1129C119.918 18.1471 119.728 18.1654 119.538 18.1674C118.381 18.1674 117.919 17.7502 117.919 16.7084V13.6568H117.082V12.7585H117.919V11.3887H118.962Z"
          fill="black"
        />
        <path
          d="M121.527 10.625H122.559V13.6027H122.642C122.78 13.2916 123.009 13.0316 123.297 12.8584C123.584 12.6852 123.917 12.6071 124.25 12.6348C124.506 12.6205 124.761 12.6642 124.998 12.7627C125.235 12.8611 125.448 13.0119 125.621 13.2043C125.795 13.3967 125.925 13.626 126.002 13.8757C126.079 14.1255 126.101 14.3896 126.067 14.6492V18.1379H125.025V14.9123C125.025 14.0492 124.632 13.6121 123.897 13.6121C123.718 13.5971 123.538 13.6223 123.369 13.6859C123.201 13.7496 123.048 13.8502 122.922 13.9807C122.795 14.1112 122.698 14.2684 122.637 14.4413C122.576 14.6143 122.553 14.7988 122.568 14.982V18.1379H121.527L121.527 10.625Z"
          fill="black"
        />
        <path
          d="M132.136 16.6778C131.994 17.1717 131.688 17.5988 131.27 17.8846C130.852 18.1704 130.35 18.2966 129.85 18.2411C129.502 18.2505 129.157 18.1823 128.838 18.0413C128.519 17.9002 128.233 17.6897 128.001 17.4244C127.769 17.159 127.597 16.8451 127.495 16.5046C127.394 16.164 127.366 15.8048 127.413 15.452C127.367 15.0982 127.395 14.7383 127.497 14.3967C127.598 14.0551 127.77 13.7397 128 13.472C128.231 13.2044 128.515 12.9905 128.833 12.8451C129.152 12.6996 129.497 12.626 129.845 12.629C131.313 12.629 132.199 13.6562 132.199 15.353V15.7251H128.474V15.7849C128.457 15.9832 128.482 16.1828 128.545 16.3709C128.609 16.559 128.71 16.7314 128.842 16.8772C128.974 17.0229 129.135 17.1387 129.313 17.2172C129.492 17.2957 129.684 17.3351 129.879 17.3329C130.128 17.3635 130.38 17.3176 130.603 17.2009C130.827 17.0843 131.011 16.9022 131.134 16.6778L132.136 16.6778ZM128.474 14.9364H131.138C131.151 14.7551 131.127 14.5729 131.068 14.4016C131.008 14.2304 130.914 14.0738 130.792 13.942C130.669 13.8101 130.521 13.706 130.357 13.6362C130.194 13.5664 130.017 13.5324 129.84 13.5366C129.66 13.5343 129.481 13.5689 129.314 13.6385C129.147 13.708 128.996 13.811 128.869 13.9415C128.741 14.0719 128.641 14.2272 128.573 14.3981C128.505 14.5689 128.471 14.752 128.474 14.9364H128.474Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_10356_139191">
          <rect width="159" height="48.0002" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default GetOnAppStore;
