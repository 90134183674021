import React from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import { IconButton, Menu, MenuItem, Popover, Select } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { isMobileSelector } from 'src/store/slices/commonSlice';
import { LangKeys, mainSliceActions } from 'src/store/slices/mainSlice';
import { useFormValidation } from 'src/screens/Home/components/Form/useForm';
import { useLanguageUtils } from '../hooks/useLanguageUtils';

export const LangSelect: React.FC<{}> = () => {
  const dispatch = useAppDispatch();

  const language = useAppSelector((state) => state.main.ui.language);
  const isMobile = useAppSelector((state) => isMobileSelector(state));

  const { setLanguage } = useLanguageUtils();

  const list = [
    {
      value: 'he',
      text: 'עברית',
    },
    {
      value: 'en',
      text: 'English',
    },
    {
      value: 'ar',
      text: 'العربية',
    },
    {
      value: 'ru',
      text: 'русский',
    },
  ];
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const x = useFormValidation();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [webOpen, setWebOpen] = React.useState(false);

  return isMobile ? (
    // Mobile input
    <>
      <IconButton aria-describedby={id} sx={{ width: '50px' }} onClick={handleClick}>
        <LanguageIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: { width: '120px' },
        }}
      >
        {list.map((item) => {
          return (
            <MenuItem
              key={item.value}
              value={item.value}
              onClick={() => {
                setLanguage(item.value as LangKeys);
                handleClose();
              }}
            >
              {item.text}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  ) : (
    // Web input
    <Select
      size="small"
      IconComponent={() => (
        <div
          style={{
            paddingLeft: 12,
            position: 'absolute',
            right: '6px',
            zIndex: 0,
            top: '8px',
            cursor: 'pointer',
          }}
          onClick={() => {
            setWebOpen(!webOpen);
          }}
        >
          <LanguageIcon htmlColor="#616161" style={{}} />
        </div>
      )}
      open={webOpen}
      sx={{ width: '160px' }}
      MenuProps={{ sx: {} }}
      value={language}
      onClick={() => {
        setWebOpen(!webOpen);
      }}
      onChange={(ev) => {
        setWebOpen(false);
        setLanguage(ev.target.value as LangKeys);
      }}
    >
      {list.map((item) => {
        return (
          <MenuItem key={item.value} value={item.value}>
            {item.text}
          </MenuItem>
        );
      })}
    </Select>
  );
};
