import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { formSelector, mainSliceActions } from 'src/store/slices/mainSlice';
import { InputConfig } from './types.form';
import { useTranslation } from 'react-i18next';
import { authRootSelector } from 'src/store/slices/authSlice';
import { useSendTFASms } from '../../../../hooks/useSendTFASms';
import { Endpoints } from 'src/api/utilis';

export const useForm = ({ textInputs }: { textInputs: InputConfig[] }) => {
  const dispatch = useAppDispatch();

  const { validateAll, validateField } = useFormValidation({ textInputs });
  const tfaSms = useSendTFASms();

  const values = useAppSelector((state) => formSelector(state).values);
  const errors = useAppSelector((state) => formSelector(state).errors);

  const onSubmit = async () => {
    try {
      const allValid = validateAll();

      if (!allValid) {
        // alert('no all valid');
        return;
      }

      const response = await tfaSms.send();

      if (!response?.isSuccess) return;

      dispatch(mainSliceActions.setStage('2fa'));
    } catch (error) {
      console.log(error);
    }
  };

  return {
    values,
    errors,
    validateAll,
    validateField,
    onSubmit,
    isLoading: tfaSms.isLoading,
  };
};

export const useFormValidation = ({ textInputs }: { textInputs: InputConfig[] }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const values = useAppSelector((state) => formSelector(state).values);
  const errors = useAppSelector((state) => formSelector(state).errors);
  const pageConfig = useAppSelector((state) => state.main.api[Endpoints.GetPageConfig].data);

  const getFieldErrorsList = (ic: InputConfig) => {
    const updatedFieldErrors = [];
    const fieldValue = values[ic.fieldKey];

    if (ic.validation.required && !fieldValue) {
      updatedFieldErrors.push(t('requiredField'));
    }

    if (fieldValue && ic.validation.regex && !ic.validation.regex.test(fieldValue)) {
      updatedFieldErrors.push(t('invalidValue'));
    }

    if (ic.validation.minLen && fieldValue.length < ic.validation.minLen) {
      updatedFieldErrors.push(`${t('tooShort')} - ${t('min')} ${ic.validation.minLen} ${t('digits')}`);
    }

    return updatedFieldErrors;
  };

  const validateField = (ic: InputConfig) => {
    dispatch(
      mainSliceActions.setFieldErrors({
        field: ic.fieldKey,
        errors: getFieldErrorsList(ic),
      })
    );
  };

  const validateAll = () => {
    const updatedErrors: typeof errors = {};

    textInputs.forEach((ic) => {
      const updatedFieldErrors = getFieldErrorsList(ic);

      if (updatedFieldErrors.length) {
        updatedErrors[ic.fieldKey] = updatedFieldErrors;
      }
    });

    if (!values.acceptedYitPrivacyAgreement) {
      updatedErrors.acceptedYitPrivacyAgreement = [t('mustAcceptPrivacyStatement')];
    }

    if (pageConfig?.accountPageConfig.sitePrivacyAgreementUrl && !values.acceptedSitePrivacyAgreement) {
      updatedErrors.acceptedSitePrivacyAgreement = [t('mustAcceptPrivacyStatement')];
    }

    dispatch(mainSliceActions.setAllErrors({ errors: updatedErrors }));

    const allValid = Object.keys(updatedErrors).length === 0;

    return allValid;
  };

  return {
    validateAll,
    validateField,
    hasErrors: Object.values(errors).some((e) => e.length > 0),
  };
};
