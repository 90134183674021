import React, { useEffect, useRef, useState } from 'react';

export const useHasVerticalScroll = (deps: any[]) => {
  const [hasVerticalScroll, setHasVerticalScroll] = useState(false);
  const divRef = useRef<any>(null);

  const memoedDeps = React.useMemo(() => deps, [deps]);

  useEffect(() => {
    const checkScroll = () => {
      const div = divRef.current;
      if (div) {
        setHasVerticalScroll(div.scrollHeight > div.clientHeight);
      }
    };

    checkScroll();

    // Optional: Re-check on window resize
    window.addEventListener('resize', checkScroll);

    // Cleanup
    return () => {
      window.removeEventListener('resize', checkScroll);
    };
  }, [memoedDeps]);

  return {
    divRef,
    hasVerticalScroll,
  };
};
