/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled, { css } from 'styled-components';
import { CircularProgress } from '@mui/material';

export const BaseButton: React.FC<{
  text: string;
  onClick: () => void;
  isLoading?: boolean;
  clickDebounce?: number;
  ButtonProps?: Partial<React.ComponentProps<typeof Button>>;
}> = ({ clickDebounce, text, isLoading = false, onClick, ButtonProps = {} }) => {
  const lastClicked = React.useRef<null | Date>(null);

  return (
    <Button
      onClick={() => {
        if (clickDebounce) {
          const currDate = new Date();

          if (lastClicked.current && currDate.getTime() - lastClicked.current.getTime() < clickDebounce) {
            return;
          }

          onClick();
          lastClicked.current = currDate;
          return;
        }

        onClick();
      }}
      isDisabled={isLoading || ButtonProps.disabled}
      {...ButtonProps}
    >
      {isLoading ? <CircularProgress size={20} color="inherit" sx={{ color: 'white' }} /> : text}
    </Button>
  );
};

const Button = styled.button<{ isDisabled: boolean }>`
  cursor: pointer;
  width: 100%;
  min-height: 40px;
  max-height: 40px;
  outline: 0;
  border: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  border-radius: 4px;
  font-size: 16px;
  color: ${(props) => (props.theme.isHightContrast ? props.theme.colors.black : props.theme.colors.white)};
  background-color: ${(props) => props.theme.colors.primary};

  font-weight: 300;
  ${(props) => (props.theme.isLinkHighlightingActive ? `text-decoration: underline;` : '')}
  ${({ isDisabled }) =>
    isDisabled
      ? css`
          background-color: ${(props) => props.theme.colors.midGray};
          color: black;
          pointer-events: none;
        `
      : ''}
  &:active {
    background-color: ${(props) => props.theme.colors.primaryDark};
  }
`;
