import { LoadingButton } from '@mui/lab';
import { t } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButton } from 'src/components/BaseButton';
import { BaseCard, CardTitle, CardSubtitle } from 'src/components/BaseCard';
import SuccessIcon from 'src/components/Icons/SuccessPic';
import SuccessPicBlackAndWhite from 'src/components/Icons/SuccessPicBlackAndWhite';
import SuccessIconMobile from 'src/components/Icons/SuccessPicMobile';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { isMobileSelector } from 'src/store/slices/commonSlice';
import { a11ySelector, mainSliceActions } from 'src/store/slices/mainSlice';
import styled from 'styled-components';

export const Success: React.FC<{}> = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const isMobile = useAppSelector((state) => isMobileSelector(state));
  const a11y = useAppSelector((state) => a11ySelector(state));

  const getSuccessImg = () => {
    if (isMobile) {
      return <SuccessIconMobile fill={a11y.contrast === 'blackAndWhite' ? '#e4e4e4' : undefined} />;
    }
    if (a11y.contrast === 'blackAndWhite') {
      return <SuccessPicBlackAndWhite />;
    }
    return <SuccessIcon />;
  };

  return (
    <BaseCard style={{ height: isMobile ? undefined : '470px' }}>
      <CardTitle>{t('signupSuccess')}</CardTitle>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
        <div style={{}}>{getSuccessImg()}</div>
      </div>
      <div style={{ paddingTop: '16px', width: '100%' }}>
        <BaseButton
          onClick={() => {
            dispatch(mainSliceActions.setStage('downloadApp'));
          }}
          text={t('ok')}
        />
      </div>
    </BaseCard>
  );
};
