import React from 'react';

function A11yHighContrast() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11.25" stroke="#494949" strokeWidth="1.5" />
      <mask id="path-2-inside-1_2708_35486" fill="white">
        <path d="M12 0C10.4241 0 8.86371 0.310389 7.4078 0.913446C5.95189 1.5165 4.62902 2.40042 3.51472 3.51472C2.40042 4.62902 1.5165 5.95189 0.913445 7.4078C0.310389 8.86371 0 10.4241 0 12C0 13.5759 0.310389 15.1363 0.913446 16.5922C1.5165 18.0481 2.40042 19.371 3.51472 20.4853C4.62902 21.5996 5.95189 22.4835 7.4078 23.0866C8.86371 23.6896 10.4241 24 12 24L12 12L12 0Z" />
      </mask>
      <path
        d="M12 0C10.4241 0 8.86371 0.310389 7.4078 0.913446C5.95189 1.5165 4.62902 2.40042 3.51472 3.51472C2.40042 4.62902 1.5165 5.95189 0.913445 7.4078C0.310389 8.86371 0 10.4241 0 12C0 13.5759 0.310389 15.1363 0.913446 16.5922C1.5165 18.0481 2.40042 19.371 3.51472 20.4853C4.62902 21.5996 5.95189 22.4835 7.4078 23.0866C8.86371 23.6896 10.4241 24 12 24L12 12L12 0Z"
        fill="#494949"
        stroke="#494949"
        strokeWidth="2"
        mask="url(#path-2-inside-1_2708_35486)"
      />
    </svg>
  );
}

export default A11yHighContrast;
